@font-face {
  font-family: "Montserrat";
  src: url("#{$cdn}/fonts/Montserrat-Thin.ttf");
  src: local("Montserrat Thin"), local("Montserrat-Thin"),
    url("#{$cdn}/fonts/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("#{$cdn}/fonts/Montserrat-ExtraLight.ttf");
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"),
    url("#{$cdn}/fonts/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("#{$cdn}/fonts/Montserrat-Light.ttf");
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("#{$cdn}/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("#{$cdn}/fonts/Montserrat-Regular.ttf");
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("#{$cdn}/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("#{$cdn}/fonts/Montserrat-Medium.ttf");
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("#{$cdn}/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("#{$cdn}/fonts/Montserrat-SemiBold.ttf");
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("#{$cdn}/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("#{$cdn}/fonts/Montserrat-Bold.ttf");
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("#{$cdn}/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("#{$cdn}/fonts/Montserrat-ExtraBold.ttf");
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("#{$cdn}/fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("#{$cdn}/fonts/Montserrat-Black.ttf");
  src: local("Montserrat Black"), local("Montserrat-Black"),
    url("#{$cdn}/fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT America";
  src: url("#{$cdn}/fonts/GT-America-Standard-Regular.woff2") format("woff2"),
    url("#{$cdn}/fonts/GT-America-Standard-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT America";
  src: url("#{$cdn}/fonts/GT-America-Standard-Medium.woff2") format("woff2"),
    url("#{$cdn}/fonts/GT-America-Standard-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT America";
  src: url("#{$cdn}/fonts/GT-America-Compressed-Bold.woff2") format("woff2"),
    url("#{$cdn}/fonts/GT-America-Compressed-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT America";
  src: url("#{$cdn}/fonts/GT-America-Standard-Black.woff2") format("woff2"),
    url("#{$cdn}/fonts/GT-America-Standard-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT America";
  src: url("#{$cdn}/fonts/GT-America-Expanded-Black-Italic.woff2")
      format("woff2"),
    url("#{$cdn}/fonts/GT-America-Expanded-Black-Italic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("#{$cdn}/fonts/Roboto-Medium.ttf") format("truetype"),
  url("#{$cdn}/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
