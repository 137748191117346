$cdn: 'https://cdn.millions.co';
@import "./reset.scss";
@import "./scss/_base";
@import "./scss/_fonts";

html,
body {
  min-height: 100%;
  position: relative;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: normal;
  font-weight: $regular_font_weight;
  font-style: normal;
  font-stretch: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $dark;
  color: $white;
}

html,
body,
#__next {
  height: 100%;
}

header {
  max-width: 100vw;
}

main {
  min-height: calc(100% - #{$mobile_header_height + $mobile_footer_height});

  @media screen and (min-width: $breakpoint_l) {
    min-height: calc(100% - #{$desktop_header_height + $desktop_footer_height});
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.recruitment-container {
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
  max-width: 1200px;

  @media screen and (min-width: $breakpoint_l) {
    padding: 0;
  }
}

textarea {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
