@font-face {
  font-family: "GT America";
  src: url("#{$cdn}/fonts/GT-America-Standard-Regular.woff2") format("woff2"),
    url("#{$cdn}/fonts/GT-America-Standard-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT America";
  src: url("#{$cdn}/fonts/GT-America-Standard-Medium.woff2") format("woff2"),
    url("#{$cdn}/fonts/GT-America-Standard-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT America";
  src: url("#{$cdn}/fonts/GT-America-Condensed-Bold.woff2") format("woff2"),
    url("#{$cdn}/fonts/GT-America-Condensed-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT America";
  src: url("#{$cdn}/fonts/GT-America-Standard-Black.woff2") format("woff2"),
    url("#{$cdn}/fonts/GT-America-Standard-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT America";
  src: url("#{$cdn}/fonts/GT-America-Expanded-Black-Italic.woff2")
      format("woff2"),
    url("#{$cdn}/fonts/GT-America-Expanded-Black-Italic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
