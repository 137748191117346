$cdn: 'https://cdn.millions.co';
@import "./reset.scss";
@import "./scss/_base";
@import "./scss/_fonts";
@import "./scss/_mixins";

html,
body {
  min-height: 100%;
  position: relative;
  font-family: "GT America", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: normal;
  font-style: normal;
  font-stretch: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-dim;
  color: $lights-high;

  @include black-scrollbar;
}

html,
body,
#__next {
  height: 100%;
}

header {
  max-width: 100vw;
}

main {
  min-height: calc(100% - #{header_height});
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
